export default class TeacherChecked extends HTMLElement {
  static tagName = "teacher-checked";

  static register(tagName = this.tagName, registry) {
    if ("customElements" in window) {
      (registry || customElements).define(tagName, this);
    }
  }

  connectedCallback() {
    this.elements = {
      submit: this.querySelector("[type='submit']"),
      checkbox: this.querySelector(".teacher-check__checkbox"),
    };

    this.#update();

    this.elements.checkbox.addEventListener("change", this.#update.bind(this));
  }

  disconnectedCallback() {
    this.elements.checkbox.removeEventListener(
      "change",
      this.#update.bind(this),
    );
  }

  #update() {
    if (this.elements.checkbox.checked) {
      this.elements.submit.removeAttribute("disabled");
    } else {
      this.elements.submit.setAttribute("disabled", true);
    }
  }
}
