export default class FlashMessage extends HTMLElement {
  static tagName = "flash-message";
  static FLASH_DURATION = 7000;

  static register(tagName = this.tagName, registry) {
    if ("customElements" in window) {
      (registry || customElements).define(tagName, this);
    }
  }

  connectedCallback() {
    this.elements = {
      button: this.querySelector(".flash__close"),
    };

    this.elements.button.addEventListener("click", this.close.bind(this));
    setTimeout(this.close.bind(this), this.constructor.FLASH_DURATION);
  }

  close() {
    this.setAttribute("hidden", true);
  }
}
