import "@tree-company/test-it-components/styles";
import "../stylesheets/application.css";
import { CarouselComponent } from "@tree-company/test-it-components";
import {
  CharacterCounter,
  ComboBox,
  CustomSelect,
  PitchBuilder,
  FiltersComponent,
  FlashMessage,
  TeacherChecked,
  PartyAnswers,
} from "../components";
import { Turbo } from "@hotwired/turbo-rails";

CustomSelect.register();
CarouselComponent.register();
PitchBuilder.register();
ComboBox.register();
CharacterCounter.register();
FiltersComponent.register();
FlashMessage.register();
TeacherChecked.register();
PartyAnswers.register();

Turbo.start();
