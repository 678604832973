export default class CustomSelect extends HTMLElement {
  static tagName = "custom-select";

  static register(tagName = this.tagName, registry) {
    if ("customElements" in window) {
      (registry || customElements).define(tagName, this);
    }
  }

  constructor() {
    super();
    this.optional = this.hasAttribute("optional");
  }

  connectedCallback() {
    this.root = this.querySelector(".custom-select");
    this.root.addEventListener("toggle", (evt) => this.#onToggle(evt));

    this.options = this.root.querySelectorAll(".custom-select__option-field");

    this.options.forEach((el) => {
      el.addEventListener("click", (evt) => this.#onClick(evt));

      // close dropdown with keyboard
      el.addEventListener("keydown", (evt) => {
        if (evt.key === "Enter" || evt.key === "Escape") {
          if (this.root.open) {
            this.root.open = false;
          }

          evt.preventDefault();
        }

        // select focused input on enter
        if (evt.key === "Enter") {
          this.selectedInput = evt.target;
        }
      });
    });

    this.root.addEventListener("focusout", (el) => {
      // close when focus changes to element outside dropdown
      if (el.relatedTarget && !this.root.contains(el.relatedTarget)) {
        this.root.open = false;
      }
    });

    this.root.addEventListener("change", (evt) => this.#onChange(evt));
    this.root.addEventListener("programmatic-input", (evt) =>
      this.#onChange(evt),
    );

    this.head = this.root.querySelector(".custom-select__head");
    this.prompt = this.head.textContent;

    // close the dropdown if anything inside (an input) / or outside is clicked
    document.addEventListener("mouseup", () => {
      this.root.open = false;
    });

    this.#onChange();
  }

  get value() {
    return this.root.querySelector("input:checked")?.value || null;
  }

  set value(val) {
    if (val === null) {
      this.root.querySelector("input:checked").checked = false;
      this.root.dispatchEvent(
        new CustomEvent("programmatic-input", { bubbles: true }),
      );
    } else {
      const el = this.root.querySelector(`input[value='${val}'`);
      el.checked = true;
      el.dispatchEvent(
        new CustomEvent("programmatic-input", { bubbles: true }),
      );
    }
  }

  get label() {
    return this.root.querySelector(
      "input:checked + .custom-select__option-label",
    )?.textContent;
  }

  get selectedInput() {
    return this.root.querySelector("input:checked");
  }

  set selectedInput(option) {
    this.value = option.value;
  }

  #onToggle(evt) {
    if (evt.newState === "open") {
      if (this.optional) {
        this.head.textContent = this.prompt;
      }

      if (this.selectedInput) {
        this.selectedInput.focus();
      } else {
        this.options[0].focus();
      }
    } else {
      this.head.textContent = this.label || this.prompt;

      // move focus to head if focus is still inside
      // (i.e. if close was not triggerd by focus-out)
      if (this.root.contains(document.activeElement)) {
        this.head.focus();
      }
    }
  }

  #onChange() {
    this.previousValue = this.value;
    this.head.textContent = this.label || this.prompt;
  }

  #onClick(evt) {
    if (this.optional && evt.target.value === this.previousValue) {
      this.value = null;
    }
  }
}
