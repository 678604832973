export default class FiltersComponent extends HTMLElement {
  static tagName = "tree-filters";

  static register(tagName = this.tagName, registry) {
    if ("customElements" in window) {
      (registry || customElements).define(tagName, this);
    }
  }

  connectedCallback() {
    this.form = this.querySelector("form");
    this.form.addEventListener("change", this.#updateFrame.bind(this));
    this.form.addEventListener(
      "programmatic-change",
      this.#updateFrame.bind(this),
    );
  }

  disconnectedCallback() {
    this.form.removeEventListener("change", this.#updateFrame.bind(this));
    this.form.removeEventListener(
      "programmatic-change",
      this.#updateFrame.bind(this),
    );
  }

  #updateFrame() {
    const formData = new FormData(this.form);
    const action = new URL(this.form.action);
    const searchParams = new URLSearchParams(formData);
    for (let [key, value] of action.searchParams.entries()) {
      searchParams.append(key, value);
    }
    action.search = searchParams.toString();
    const src = action.toString();
    // Manually set next state in history
    window.history.pushState(action.search, "", src);
    document
      .querySelector('[data-tree-filters-target="frame"]')
      .setAttribute("src", src);
  }
}
