export default class PartyAnswers extends HTMLElement {
  static tagName = "party-answers";

  static register(tagName = this.tagName, registry) {
    if ("customElements" in window) {
      (registry || customElements).define(tagName, this);
    }
  }

  connectedCallback() {
    this.elements = {
      answers_container: this.querySelector(".card__answers-container"),
      answers: this.querySelectorAll(".card__answer"),
      show_more: this.querySelector(".card__see-more"),
      show_less: this.querySelector(".card__see-less"),
      preview: this.querySelector(".card__answers-preview"),
    };

    this.opened = false;

    this.elements.show_more.addEventListener("click", this.toggle.bind(this));
    this.elements.show_less.addEventListener("click", this.toggle.bind(this));
  }

  disconnectedCallback() {
    this.elements.show_more.addEventListener("click", this.toggle.bind(this));
    this.elements.show_less.addEventListener("click", this.toggle.bind(this));
  }

  toggle() {
    this.opened = !this.opened;
  }

  set opened(value) {
    if (value) {
      this.setAttribute("data-opened", true);
    } else {
      this.removeAttribute("data-opened");
    }

    if (!value) {
      // close
      this.elements.preview.removeAttribute("hidden");
      this.elements.answers_container.setAttribute("hidden", true);
      this.elements.show_more.removeAttribute("hidden");
    } else {
      // open
      this.elements.preview.setAttribute("hidden", true);
      this.elements.answers_container.removeAttribute("hidden");
      this.elements.show_more.setAttribute("hidden", true);
    }
  }

  get opened() {
    return this.hasAttribute("data-opened");
  }
}
