export default class CharacterCounter extends HTMLElement {
  static tagName = "character-counter";

  static register(tagName = this.tagName, registry) {
    if ("customElements" in window) {
      (registry || customElements).define(tagName, this);
    }
  }

  connectedCallback() {
    window.requestAnimationFrame(() => {
      this.elements = {
        input: this.querySelector("input") || this.querySelector("textarea"),
        counter: this.querySelector(
          '[data-character-counter-target="counter"]',
        ),
      };
      this.maxLength = this.elements.input.maxLength;
      this.elements.input.addEventListener(
        "input",
        this.#updateCounter.bind(this),
      );

      this.#updateCounter();
    });
  }

  disconnectedCallback() {
    if (this.elements?.input) {
      this.elements.input.removeEventListener(
        "input",
        this.#updateCounter.bind(this),
      );
    }
  }

  #updateCounter() {
    if (this.maxLength === undefined) return;

    this.elements.counter.innerText = this.dataset.template
      .replace("{{count}}", this.elements.input.value.length)
      .replace("{{maximum}}", this.maxLength);
  }
}
