export default class PitchBuilder extends HTMLElement {
  static tagName = "pitch-builder";

  static register(tagName = this.tagName, registry) {
    if ("customElements" in window) {
      (registry || customElements).define(tagName, this);
    }
  }

  connectedCallback() {
    this.questions = this.querySelectorAll(".pitch__input");

    this.questions.forEach((question) => {
      question.addEventListener("input", () => {
        this.querySelectorAll(`${question.dataset.target}`).forEach(
          (target) => {
            target.textContent = question.value;
          },
        );
      });
    });
  }
}
